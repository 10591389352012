import WebAccordition from "./components/Accordition";
const Tables = () => {
  return (
    <div className="mt-5">
    <div className="w-full">
      <WebAccordition/>
    </div>
  </div>
  );
};

export default Tables;
