import React, { useState, useEffect } from "react";
import { FaCheckCircle, FaClock, FaBan } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";

const EditQueryForm = ({ query, onSubmit, onCancel }) => {
  const [status, setStatus] = useState(query.status);

  useEffect(() => {
    setStatus(query.status);
  }, [query]);

  const handleSave = () => {
    onSubmit({ ...query, status });
  };

  const getStatusIcon = () => {
    switch (status.toLowerCase()) {
      case "pending":
        return <FaClock className="text-xl text-yellow-500" />;
      case "rejected":
        return <TiDelete className="text-xl text-red-500" />;
      case "in progress":
        return <FaClock className="text-xl text-blue-500" />;
      case "resolved":
        return <FaCheckCircle className="text-xl text-green-500" />;
      default:
        return <FaBan className="text-xl text-gray-500" />;
    }
  };

  return (
    <div className="p-4">
      <h2 className="mb-4 text-xl font-bold">Edit Query</h2>

      <div className="mb-4">
        <label
          htmlFor="status"
          className="block text-sm font-medium text-gray-600"
        >
          Status:
        </label>
        <div className="flex items-center">
          {getStatusIcon()}
          <select
            id="status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="ml-2 mt-1 block w-full rounded-md border border-gray-300 p-2 focus:border-blue-300 focus:outline-none focus:ring dark:border-gray-600 dark:bg-gray-800 dark:text-white dark:focus:ring-blue-500"
          >
            <option value="Pending">Pending</option>
            <option value="Rejected">Rejected</option>
            <option value="In Progress">In Progress</option>
            <option value="Resolved">Resolved</option>
          </select>
        </div>
      </div>

      <div className="flex space-x-4">
        <button
          onClick={handleSave}
          className="rounded-full bg-blue-500 px-4 py-2 text-white hover:bg-blue-700"
        >
          Save
        </button>
        <button
          onClick={onCancel}
          className="rounded-full bg-red-500 px-4 py-2 text-white hover:bg-red-700"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default EditQueryForm;
